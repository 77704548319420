import 'swiper/modules/pagination.css';
import 'swiper/swiper.css';
import { initContacts } from './contacts';
import { initExamples } from './examples';
import { initHeader } from "./header";
import { initMaskito } from "./mask";
import { modalInstance } from "./modal";
import { initReviews } from "./reviews";


initReviews();
initMaskito();
initHeader();
initExamples();
initContacts();

const modalData = {
  test: {
    1: {
      title: 'Управление активами пищевого производства',
    },
    2: {
      title: 'Управление активами добывающей отрасли',
    },
    3: {
      title: 'Управление активами автотранспорта',
    },
    4: {
      title: 'Управление активами торгового центра',
    },
    5: {
      title: 'Управление активами производственного предприятия',
    },
    6: {
      title: 'Управление активами энергетической компании',
    },
    7: {
      title: 'Управление активами сервисной компании',
    },
    8: {
      title: 'Управление активами коммунального предприятия',
    },
    9: {
      title: 'Индивидуальное предложение для организаций различных сфер деятельности',
    },
  },
  tariff: {
    1: {
      tariff: 'Стандартный',
    },
    2: {
      tariff: 'Продвинутый',
    },
    3: {
      tariff: 'Индивидуальный',
    }
  },
  default: {}
}

const modalTriggers = [
  document.getElementById('management_button_main'),
  document.querySelector('.management__button'),
  document.querySelector('.management__taxes-demo-btn'),
  document.querySelector('.management__fast-return-btn'),
  Array.from(document.querySelectorAll('.management__assets-card-btn')),
  Array.from(document.querySelectorAll('.management__taxes-btn')),
]

function addListenerToModalTrigger($el) {
  $el.addEventListener('click', () => {
    const type = $el.dataset.type || 'default';
    const id = $el.dataset.id;
    const data = id ? { ...modalData[type][id] || {}, id } : null;

    modalInstance.open({ type, data });
  })
}

for (const $el of modalTriggers) {
  if (!$el) continue;

  if (Array.isArray($el)) {
    $el.forEach(($elItem) => {
      addListenerToModalTrigger($elItem);
    })
  } else {
    addListenerToModalTrigger($el);
  }
}

// modal
document.getElementById('modal_to_home').addEventListener('click', () => {
  modalInstance.close();
  window.scrollTo({ top: 0, behavior: "smooth" })
})
