export class RequestForm {
  constructor(formId, requestCallback) {
    this.$form = document.getElementById(formId);
    this.$formButton = this.$form.querySelector(`[type="submit"]`);

    this.requestCallback = requestCallback;

    this.initForm();

    window.captchaOnLoadFunction = this.captchaOnLoadFunction.bind(this);
  }

  captchaOnLoadFunction() {
    if (!window.smartCaptcha) {
      return;
    }

    window.smartCaptcha.render('captcha-container', {
      sitekey: 'ysc1_sGJqJv3vdQagU4MBY94oSD6gcbKQr41v7ye5jXQY1b162dd4',
      invisible: true, // Сделать капчу невидимой
      shieldPosition: 'bottom-right',
      callback: this.sendForm.bind(this)
    });
  }

  initForm() {
    this.$form.addEventListener('submit', async (e) => {
      e.preventDefault();

      this.$formButton.classList.add('modal__btn--loading');
      this.$formButton.disabled = true;

      if (!window.smartCaptcha) {
        return;
      }

      window.smartCaptcha.execute();
    })
  }

  sendForm() {
    fetch(this.$form.action, {
      method: 'POST',
      body: new FormData(this.$form),
      headers: {
        CSRF: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then((response) => {

      window.ym(97540274, 'reachGoal', 'send_form');
      this.requestCallback()

    }).catch(() => {
      alert("Произошла ошибка");
      console.error('Ошибка отправки данных')
    }).finally(() => {
      this.$formButton.classList.remove('modal__btn--loading');
      this.$formButton.disabled = false;
    });
  }
}