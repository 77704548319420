const selectedOptionClass = 'modal__selector-option_selected'

export class Selector {
  constructor($el) {
    this.$el = $el
    this.$selectorWrapper = this.$el.querySelector('#modal_tariff_selector_wrapper');
    this.$dropdown = this.$selectorWrapper.querySelector('.modal__selector-dropdown');
    this.$activeSelectorContainer = this.$selectorWrapper.querySelector('#modal_tariff_selector_active');
    this.$selectorIcon = this.$selectorWrapper.querySelector('.modal__tariff-selector-icon');
    this.$tariffs = Array.from(this.$selectorWrapper.querySelectorAll('.modal__selector-option'));
    this.activeSelectorContainerCallback = this._activeSelectorContainerOnClick.bind(this);
    this.onTariffClickCallback = this._onTariffClick.bind(this);
    this.$modalTariffSelectorInput = this.$selectorWrapper.querySelector('#modal_tariff_selector_input');
    this.selectorClickOutsideInstance = null;
  }

  _initTariffs() {
    this.$tariffs.forEach($tariff => {
      $tariff.classList.remove(selectedOptionClass);
      $tariff.addEventListener('click', this.onTariffClickCallback)
    })
  }

  _setDropdown(isRemove) {
    const activeClass = 'active';

    if (isRemove) {
      this.$dropdown.classList.remove(activeClass);
      this.$selectorIcon.classList.remove(activeClass);
      this.selectorClickOutsideInstance && this.selectorClickOutsideInstance.remove();
    } else {
      this.$dropdown.classList.add(activeClass);
      this.$selectorIcon.classList.add(activeClass);
    }
  }

  _activeSelectorContainerOnClick() {
    if (this.$dropdown.classList.contains('active')) {
      this._setDropdown(true);
    } else {
      this._setDropdown();
      this._initClickOutside();
    }
  }

  _initClickOutside() {
    setTimeout(() => {
      this.selectorClickOutsideInstance = new onClickOutside(this.$dropdown, (e) => {
        this._setDropdown(true);
      });
    }, 0);
  }

  _onTariffClick(e) {
    const $el = e.currentTarget;

    this.$tariffs.forEach(($tariff) => {
      $tariff.classList.remove(selectedOptionClass);
    })
    $el.classList.add(selectedOptionClass)

    this.$modalTariffSelectorInput.value = $el.dataset.value;
    this.$activeSelectorContainer.innerHTML = $el.innerHTML;
    this._setDropdown(true);
  }

  setDefault(tariff, id) {
    const $tariffToSet = this.$selectorWrapper.querySelector(`.modal__selector-option[data-value="${id}"]`);

    if (!tariff) {
      this.$selectorWrapper.style.display = 'none';
      this.$modalTariffSelectorInput.value = null;
    } else {
      this.$selectorWrapper.style.display = 'block';
      this.$activeSelectorContainer.innerHTML = $tariffToSet.innerHTML;
      this.$modalTariffSelectorInput.value = id;
      $tariffToSet.classList.add(selectedOptionClass)
    }
  }

  init() {
    this._initTariffs();

    this.$activeSelectorContainer.addEventListener('click', this.activeSelectorContainerCallback)
  }

  destroy() {
    this.$tariffs.forEach(tariff => {
      tariff.removeEventListener('click', this._onTariffClick);
    })

    this.$activeSelectorContainer.removeEventListener('click', this.activeSelectorContainerCallback);

    this.$activeSelectorContainer.innerHTML = 'Выбрать тариф'
  }
}

