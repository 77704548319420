import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

let examplesSwiper = null;

export function initExamples() {
    initExamplesSwiper();
    initAccordion();
}

function initAccordion() {
    document.querySelectorAll('.management__examples-toggler').forEach(togglerEl => {
        togglerEl.addEventListener('click', () => {
            togglerEl.classList.toggle('management__examples-toggler--active');
            const collapseEl = togglerEl.closest('.management__examples-content').querySelector('.management__examples-collapse');
            collapseEl.classList.toggle('management__examples-collapse--active');
            setTimeout(() => examplesSwiper.update(), 650)
        })
    });
}


function initExamplesSwiper() {
    examplesSwiper = new Swiper('.examples-swiper', {
        slidesPerView: 'auto',
        modules: [Navigation, Pagination],
        pagination: {
            el: '.management__swiper-btn-next',
            clickable: true,
        },
        centeredSlides: true,
        spaceBetween: 30,
        autoHeight: true,
        navigation: {
            nextEl: '.management__swiper-btn_right',
            prevEl: '.management__swiper-btn_left',
        },

    });
}
