import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { reviewsData } from "../data/reviews.data";
import { setInnerHTMLToEl, setInnerTextToEl } from "./helpers";

const $reviewsContainer = document.getElementById('reviews_container');

class ReviewItem {
  constructor(data) {
    this.item = document.getElementById('review_template').content.cloneNode(true);

    setInnerHTMLToEl(this.item, '.management__swiper-company', data.company)
    setInnerTextToEl(this.item, '.management__swiper-client', `${data.fio},\n ${data.position}`)
    setInnerHTMLToEl(this.item, '.management__swiper-review', data.text)

    this._setImage(data.img);
    const id = this._addId(data.id);
    this._addExpandLogic(id);
  }

  _addId(srcId) {
    const id = `management_review_swiper_${srcId}`;
    const rootEl = this.item.firstChild.nextSibling;
    rootEl.setAttribute('id', id);
    return id
  }

  _addExpandLogic(id) {
    const $root = this.item.getElementById(id);
    const $expandButton = $root.querySelector('.management__swiper-expand-btn')

    const $reviewContent = $root.querySelector('.management__swiper-review')
    const expandedClass = 'management__swiper-review_expanded'

    const clickHandler = (click) => {
      if ($reviewContent.classList.contains(expandedClass)) {
        $reviewContent.classList.remove(expandedClass);
        $expandButton.innerHTML = 'Читать полностью'
      } else {
        $reviewContent.classList.add(expandedClass);
        $expandButton.innerHTML = 'Свернуть'
      }
    }

    $expandButton.addEventListener('click', clickHandler)
  }

  _setImage(value) {
    const $el = this.item.querySelector('.management__swiper-content-img');
    $el.setAttribute('src', value);
  }

  appendToParent($parent) {
    $parent.append(this.item)
  }
}

export function initReviews() {
  if (!$reviewsContainer) return;

  for (const reviewDataIndex in reviewsData) {
    const review = new ReviewItem(reviewsData[reviewDataIndex])
    review.appendToParent($reviewsContainer)
  }

  initFeedbackSwiper();
}

function initFeedbackSwiper() {
  new Swiper('.reviews-swiper', {
    slidesPerView: 'auto',
    modules: [Navigation, Pagination],
    pagination: {
      el: '.management__swiper-btn-next',
      clickable: true,
    },
    centeredSlides: true,
    spaceBetween: 30,
    navigation: {
      nextEl: '.management__swiper-btn_right',
      prevEl: '.management__swiper-btn_left',
    },
  });
}
