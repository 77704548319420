// welcome to hell ^_^
import { RequestForm } from "./form";
import { initMaskito } from "./mask";
import { Selector } from "./selector";

const steps = {
  thanks: 'thanks',
  form: 'form',
}

const states = {
  closed: 'closed',
  opened: 'opened'
}

function setWindowScroll(isHide) {
  const $root = document.documentElement;

  if (isHide) {
    $root.style.overflow = 'hidden';
  } else {
    $root.style.overflow = 'auto';
  }
}

class Modal {
  constructor($el) {
    this.$el = $el;
    this.maskito = null;
    this.state = states.closed;
    new RequestForm('modal_form', this.onSendRequestCallback.bind(this), true)
    new onClickOutside(this.$el.querySelector('.modal__body'), () => {
      if (this.state === states.opened) {
          this.close();
      }
    });

    this._initClose();
    this.selector = new Selector(this.$el);
  }

  async onSendRequestCallback() {
    this.showStep2();
  }

  _initClose() {
    const $el = this.$el.querySelector('.modal__close-btn');

    $el.addEventListener('click', () => {
      this.close();
    })
  }

  showStep2() {
    this.step = steps.thanks;
    this.renderModal();
  }

  open({ type, data }) {
    this.selector.init();
    this.data = document
    this.renderModal({ type, data });
    setWindowScroll(true);

    this.$el.classList.add('modal_visible')
    setTimeout(() => {
      this.state = states.opened;
    }, 0)
  }

  renderModal({ type, data } = {}) {
    if (this.step === steps.thanks) {
      this.renderStep2();
    } else {
      this.renderStep1(type, data);
    }
  }
  
  renderStep1(type, data) {
    this.setStep(steps.form);
    this.maskito = initMaskito('.modal [name=phone]');
    if (type === 'tariff') {
      this.renderTariff(data);
    } else if (type === 'test') {
      this.renderTest(data);
    } else {
      this.renderDefault();
    }
  }

  renderStep2() {
    this.setStep(steps.thanks);
  }

  renderDefault() {
    this.setTitle('Оставить заявку');
    this.setDescription('Для тестирования продукта на бизнес-процессах или уточнения информации о тарифах и условиях свяжитесь с нами удобным способом.');
    this.setSelector();
  }

  renderTariff(data) {
    this.setTitle('Оставить заявку');
    this.setDescription('Для внедрения продукта в свои бизнес-процессы или уточнения дополнительной информации о тарифах и условиях свяжитесь с нами удобным способом.');
    this.setSelector({ tariff: data.tariff, id: data.id });
  }

  renderTest(data) {
    this.setTitle(`Оставить заявку на тестирование продукта «${data.title}»`);
    this.setDescription('Для тестирования продукта на бизнес-процессах или уточнения информации о тарифах и условиях свяжитесь с нами удобным способом.');
    this.setSelector();
  }

  resetState() {
    this.data = null;
    this.step = steps.form;
    
    if (this.maskito) {
      this.maskito.destroy();
      this.maskito = null;
    }
  }
  
  setStep(step) {
    const $thanks = this.$el.querySelector('.modal__step_thanks');
    const $form = this.$el.querySelector('.modal__step_form');
    const activeClass = 'active';
    
    if (step === steps.thanks) {
      $thanks.classList.add(activeClass)
      $form.classList.remove(activeClass)
    } else {
      $thanks.classList.remove(activeClass)
      $form.classList.add(activeClass)
    }
  }

  setTitle(value) {
    const $title = this.$el.querySelector('.modal__title');
    $title.innerText = value;
  }

  setDescription(value) {
    const $description = this.$el.querySelector('.modal__description');
    $description.innerText = value;
  }

  setSelector({ tariff, id } = {}) {
    this.selector.setDefault(tariff, id)
  }

  close() {
    this.$el.classList.remove('modal_visible')
    setWindowScroll(false);

    this.state = states.closed;
    this.resetState();
    this.selector.destroy();
  }
}

const $modal = document.getElementById('modal');
const modalInstance = new Modal($modal);

export { Modal, modalInstance };

