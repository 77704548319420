export const reviewsData = [
  {
    id: 1,
    img: new URL('/images/swiper-logo.png', import.meta.url),
    company: 'ГК "За Родину"',
    fio: 'Игорь Геннадьевич Кобзарев',
    position: 'Главный инженер ТМ «За Родину»',
    text: "Во‑первых, нужно было регламентировать все действия по ремонту оборудования, обязательно отследить порядок их выполнения, а затем подтвердить. Поэтому на первом этапе для того, чтобы сформировать в автоматическом режиме годовой план планово-предупредительных работ, мы создали технологические карты. Этим занимались специалисты службы главного инженера под моим руководством. Затем технологические карты были оцифрованы. Эти работы выполнила калининградская IT-компания «Продман». Технологические карты сведены программным продуктом для автоматического формирования и визуализации годового плана планово-предупредительных работ» \n",
  },
  // {
  //   id: 2,
  //   img: new URL('/images/swiper-logo.png', import.meta.url),
  //   company: 'ООО "За Нее"',
  //   fio: 'Павел Геннадьевич Кобзарев',
  //   position: 'Главный инженер ТМ «За Нее»',
  //   text: "Во‑первых, нужно было регламентировать все действия по ремонту оборудования, обязательно отследить порядок их выполнения, а затем подтвердить. Поэтому на первом этапе для того, чтобы сформировать в автоматическом режиме годовой план планово-предупредительных работ, мы создали технологические карты. Этим занимались специалисты службы главного инженера под моим руководством. Затем технологические карты были оцифрованы. Эти работы выполнила калининградская IT-компания «Продман». Технологические карты сведены программным продуктом для автоматического формирования и визуализации годового плана планово-предупредительных работ» \n",
  // },
]
