export function initContacts() {
    loadScript("https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=49a0ed12-5927-4ab5-a1b7-512f24eee33b", loadMap);
}

function loadScript(url, callback) {
    var script = document.createElement("script");

    if (script.readyState) { // IE
        script.onreadystatechange = function () {
            if (script.readyState == "loaded" ||
                script.readyState == "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else { // Другие браузеры
        script.onload = function () {
            callback();
        };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

const loadMap = () => {
    console.log("Maps script loaded");
    ymaps.ready(initYandexMaps);
};

function initYandexMaps() {

    if (!document.getElementById('ymap')) return;

    var myMap;

    let zoom = 13;
    let coordScale = 0.9997;

    if (window.innerWidth < 576) {
        zoom = 12;
    } else if (window.innerWidth <= 768) {
        zoom = 12.5;
        coordScale = 1;
    }

    let center = [0, 0]; // находим центр карты
    let pointsCount = 0;
    let points = [];


    const coordinates = [54.705512, 20.583413];

    center[0] += Number(coordinates[0] * coordScale);
    center[1] += Number(coordinates[1]);

    pointsCount++;

    const point = new ymaps.Placemark(coordinates, {
    });

    points.push(point);


    center[0] /= pointsCount;
    center[1] /= pointsCount;

    myMap = new ymaps.Map('ymap', {
        center: center,
        zoom: zoom,
        controls: ['zoomControl']
    }, {
        searchControlProvider: 'yandex#search'
    });

    for (const point of points) {
        myMap.geoObjects.add(point);
    }
}